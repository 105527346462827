import {
	checkpaypassword,
	balanceConfig
} from "@/api/order/payment"
import {
	addressList,
	saveAddress,
	setDefault,
	deleteAddress,
	addressInfo
} from "@/api/member/member"
import {
	payment,
	calculate,
	orderCreate
} from "@/api/seckill"
import {
	getArea
} from "@/api/address"
import {
	mapGetters
} from "vuex"

export default {
	name: "seckill_payment",
	components: {},
	data: () => {
		var checkMobile = (rule, value, callback) => {
			
			
				callback()
			
		}

		return {
			dialogVisible: false,
			memberAddress: {}, //รายการที่อยู่สําหรับจัดส่ง
			addressId: 0, //ที่อยู่จัดส่ง
			addressForm: {
				id: 0,
				name: "",
				mobile: "",
				telephone: "",
				province_id: "",
				city_id: "",
				district_id: "",
				community_id: "",
				address: "",
				full_address: "",
				is_default: "",
				longitude: "",
				latitude: ""
			},
			pickerValueArray: {},
			cityArr: {},
			districtArr: {},
			addressRules: {
				name: [{
						required: true,
						message: "กรุณาใส่ผู้รับสินค้า",
						trigger: "blur"
					},
					{
						min: 1,
						max: 20,
						message: "ความยาวอยู่ใน 1 ถึง 20 อักขระ",
						trigger: "blur"
					}
				],
				mobile: [{
					required: true,
					validator: checkMobile,
					trigger: "change"
				}],
				province: [{
					required: true,
					message: "โปรดเลือกจังหวัด",
					trigger: "change"
				}],
				city: [{
					required: true,
					message: "โปรดเลือกเมือง",
					trigger: "change"
				}],
				district: [{
					required: true,
					message: "โปรดเลือกโซน/เขต",
					trigger: "change"
				}],
				address: [{
					required: true,
					message: "กรุณากรอกที่อยู่แบบเต็มของคุณ",
					trigger: "change"
				}]
			},
			isSend: false,
			orderCreateData: {
				is_balance: 0,
				pay_password: ""
			},
			orderPaymentData: {
				goods_money: 0,
				pay_money: 0,
				shop_goods_list: {
					site_name: "",
					express_type: [],
					coupon_list: []
				},
				seckill_info: {
					name: ""
				},
				member_account: {
					balance: 0,
					is_pay_password: 0
				}
			},
			siteCoupon: {
				site_id: 0,
				data: []
			},
			siteDelivery: {
				site_id: 0,
				data: []
			},
			dialogStore: false,
			promotionInfo: false,
			storeList: {},
			sitePromotion: [],
			isSub: false,
			dialogpay: false,
			password: "",
			fullscreenLoading: true,
			deliveryTime: false,
			timeTip: "เลือกเวลาจัดส่ง",
			time: null,
			addressShow: false,
			storeRadio: false,
			balance_show : 1
		}
	},
	computed: {
		...mapGetters(["seckillOrderCreateData", "defaultGoodsImage", "city"])
	},
	mounted() {},
	created() {
		this.getMemberAddress()
		this.getOrderPaymentData()
		this.getBalanceConfig()
	},

	filters: {
		/**
		 * จํานวนเอาต์พุตที่จัดรูปแบบ
		 * @param {Object} money
		 */
		moneyFormat(money) {
			return parseFloat(money).toFixed(2)
		},
		/**
		 * เลือกรับส่วนลดจากร้านค้า
		 */
		promotion(data) {
			let promotion = ""
			if (data) {
				Object.keys(data).forEach(key => {
					promotion += data[key].content + "　"
				})
			}
			return promotion
		}
	},
	methods: {
		//รับการกําหนดค่าการชําระเงินยอดคงเหลือ
		getBalanceConfig() {
			balanceConfig()
			.then(res => {
				const {
					code,
					message,
					data
				} = res
			
				if (code >= 0) {
					this.balance_show = data.balance_show;
				}
			})
			.catch(err => {
				const {
					code,
					message,
					data
				} = err
				// this.$notify.error({title: 'Error',message: message});
			})
		},
		//รับที่อยู่จัดส่ง
		getMemberAddress() {
			addressList({
					page_size: 0
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data && data.list) {
						let that = this
						this.memberAddress = data.list
						data.list.forEach(function(e) {
							if (e.is_default == 1) {
								that.addressId = e.id
							}
						})
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},

		//ตั้งค่าที่อยู่สําหรับจัดส่งของสมาชิก
		setMemberAddress(params) {
			this.addressId = params
			setDefault({
					id: params
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					this.orderCalculate()
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},

		//ลบที่อยู่สําหรับจัดส่งของสมาชิก
		deleteMemberAddress(params) {
			deleteAddress({
					id: params
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						this.$notify({
							message: message,
							title: 'เรียบร้อยแล้ว',type: "success"
						})
						this.getMemberAddress()
					} else {
						this.$notify({
							message: message,
							title: "คำเตือน",type: "warning"
						})
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				})
		},

		//เปิดเลเยอร์ป๊อปอัปเพิ่มที่อยู่สําหรับจัดส่ง
		addAddressShow() {
			this.dialogVisible = true

			this.addressForm.id = 0
			this.addressForm.name = ""
			this.addressForm.mobile = ""
			this.addressForm.telephone = ""
			this.addressForm.province_id = ""
			this.addressForm.city_id = ""
			this.addressForm.district_id = ""
			this.addressForm.community_id = ""
			this.addressForm.address = ""
			this.addressForm.full_address = ""
			this.addressForm.is_default = ""
			this.addressForm.longitude = ""
			this.addressForm.latitude = ""
			this.$nextTick(() => {
				this.$refs.form.resetFields()
			})
			this.cityArr = {}
			this.districtArr = {}
			this.getAddress(0)
		},

		//รับที่อยู่
		getAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//โหลดจังหวัด
					pid = 0
					break
				case 1:
					//โหลดเมือง
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					this.addressForm.city_id = ""
					this.addressForm.district_id = ""
					break
				case 2:
					//โหลดเขต
					pid = this.addressForm.city_id
					that.districtArr = {}
					this.addressForm.district_id = ""
					break
			}

			getArea({
					pid: pid
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//โหลดเมือง
								that.cityArr = data
								break
							case 2:
								//โหลดเขต
								that.districtArr = data
								break
						}
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},

		//แก้ไขที่อยู่ เริ่ม ต้น
		initAddress(type) {
			let pid = 0
			let that = this
			switch (type) {
				case 0:
					//โหลดจังหวัด
					pid = 0
					break
				case 1:
					//โหลดเมือง
					pid = this.addressForm.province_id
					that.cityArr = {}
					that.districtArr = {}
					break
				case 2:
					//โหลดเขต
					pid = this.addressForm.city_id
					that.districtArr = {}
					break
			}

			getArea({
					pid: pid
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (data) {
						switch (type) {
							case 0:
								that.pickerValueArray = data
								break
							case 1:
								//โหลดเมือง
								that.cityArr = data
								break
							case 2:
								//โหลดเขต
								that.districtArr = data
								break
						}
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},

		//ใหม่/แก้ไขที่อยู่สําหรับจัดส่ง
		saveAddress(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.isSend) {
						return false
					}

					if (!this.addressForm.id) {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = {
							name: this.addressForm.name,
							mobile: this.addressForm.mobile,
							telephone: this.addressForm.telephone,
							province_id: this.addressForm.province_id,
							city_id: this.addressForm.city_id,
							district_id: this.addressForm.district_id,
							community_id: "",
							address: this.addressForm.address,
							full_address: this.addressForm.full_address,
							longitude: this.addressForm.longitude,
							latitude: this.addressForm.latitude,
							is_default: this.addressForm.is_default,
							url: "add"
						}

						if (!data.province_id) {
							this.$notify({
								message: "โปรดเลือกจังหวัด",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.city_id) {
							this.$notify({
								message: "โปรดเลือกเมือง",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.district_id) {
							this.$notify({
								message: "โปรดเลือกโซน/เขต",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						this.isSend = true

						addMemberAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.setMemberAddress(data)

									this.$notify({
										message: message,
										title: 'เรียบร้อยแล้ว',type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$notify({
										message: message,
										title: "คำเตือน",type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$notify.error({title: 'Error',message: message});
							})
					} else {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = this.addressForm
						if (!data.province_id) {
							this.$notify({
								message: "โปรดเลือกจังหวัด",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.city_id) {
							this.$notify({
								message: "โปรดเลือกเมือง",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.district_id) {
							this.$notify({
								message: "โปรดเลือกโซน/เขต",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						this.isSend = true
						this.setMemberAddress(data.id);
						data.url = "edit";
						saveAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.$notify({
										message: message,
										title: 'เรียบร้อยแล้ว',type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$notify({
										message: message,
										title: "คำเตือน",type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$notify.error({title: 'Error',message: message});
							})
					}
				} else {
					return false
				}
			})
		},
		addmemberAddress(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.isSend) {
						return false
					}
		
					if (!this.addressForm.id) {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = {
							name: this.addressForm.name,
							mobile: this.addressForm.mobile,
							telephone: this.addressForm.telephone,
							province_id: this.addressForm.province_id,
							city_id: this.addressForm.city_id,
							district_id: this.addressForm.district_id,
							community_id: "",
							address: this.addressForm.address,
							full_address: this.addressForm.full_address,
							longitude: this.addressForm.longitude,
							latitude: this.addressForm.latitude,
							is_default: this.addressForm.is_default,
							url: 'add'
						}
		
						if (!data.province_id || data.province_id <= 0 ) {
							this.$notify({
								message: "กรุณาเลือกจังหวัดให้ถูกต้อง",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.city_id || data.city_id <= 0) {
							this.$notify({
								message: "โปรดเลือกเมืองให้ถูกต้อง",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.district_id || data.district_id <= 0) {
							this.$notify({
								message: "กรุณาเลือกโซนให้ถูกต้อง/เขต",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						this.isSend = true
		
						saveAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.setMemberAddress(data)
		
									this.$notify({
										message: message,
										title: 'เรียบร้อยแล้ว',type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$notify({
										message: message,
										title: "คำเตือน",type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$notify.error({title: 'Error',message: message});
							})
					} else {
						this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" +
							this.$refs.district.selectedLabel
						let data = this.addressForm
						if (!data.province_id) {
							this.$notify({
								message: "โปรดเลือกจังหวัด",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.city_id) {
							this.$notify({
								message: "โปรดเลือกเมือง",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						if (!data.district_id) {
							this.$notify({
								message: "โปรดเลือกโซน/เขต",
								title: "คำเตือน",type: "warning"
							})
							return false
						}
						this.isSend = true
						this.setMemberAddress(data.id);
						data.url = "edit";
						saveAddress(data)
							.then(res => {
								const {
									code,
									message,
									data
								} = res
								if (data) {
									this.$notify({
										message: message,
										title: 'เรียบร้อยแล้ว',type: "success"
									})
									this.dialogVisible = false
									this.getMemberAddress()
									this.getOrderPaymentData()
								} else {
									this.$notify({
										message: message,
										title: "คำเตือน",type: "warning"
									})
								}
								this.isSend = false
							})
							.catch(err => {
								const {
									code,
									message,
									data
								} = err
								this.$notify.error({title: 'Error',message: message});
							})
					}
				} else {
					return false
				}
			})
		},
		//แก้ไขที่อยู่สําหรับจัดส่ง
		editAddress(id) {
			addressInfo({
					id: id
				})
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					this.addressForm = {
						id: data.id,
						name: data.name,
						mobile: data.mobile,
						telephone: data.telephone,
						province_id: data.province_id,
						city_id: "",
						district_id: "",
						community_id: "",
						address: data.address,
						full_address: data.full_address,
						is_default: data.is_default,
						longitude: data.longitude,
						latitude: data.latitude
					}
					this.initAddress(0)
					this.initAddress(1)
					this.addressForm.city_id = data.city_id
					this.initAddress(2)
					this.addressForm.district_id = data.district_id

					this.dialogVisible = true
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},

		/**
		 * รับข้อมูลการเริ่มต้นคําสั่งซื้อ
		 */
		getOrderPaymentData() {
			this.orderCreateData = this.seckillOrderCreateData

			if (!this.orderCreateData) {
				this.$notify({
					message: "ไม่ได้รับข้อมูลที่จําเป็นในการสร้างคําสั่งซื้อ！", //ข้อมูลที่ได้รับแจ้ง
					title: "คำเตือน",type: "warning",
					offset: 225,
					duration: 3000,
					onClose: () => {
						this.$router.go(-1)
						return false
					}
				})
				return
			}





			this.orderCreateData.web_city = this.city ? this.city.id : 0

			payment(this.orderCreateData)
				.then(res => {
					const {
						code,
						message,
						data
					} = res

					if (code >= 0) {
						this.orderPaymentData = res.data
						this.handlePaymentData()
					} else {
						this.$notify({
							message: "ไม่ได้รับข้อมูลที่จําเป็นในการสร้างคําสั่งซื้อ！", //ข้อมูลที่ได้รับแจ้ง
							title: "คำเตือน",type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
				})
		},
		/**
		 * ประมวลผลข้อมูลใบสั่งชําระเงิน
		 */
		handlePaymentData() {
			this.orderCreateData.delivery = {}
			this.orderCreateData.coupon = {}
			this.orderCreateData.is_balance = 0
			this.orderCreateData.pay_password = ""

			var data = this.orderPaymentData

			console.log(this.orderPaymentData.shop_goods_list.express_type)
			if (this.orderPaymentData.shop_goods_list.express_type.length > 1) {
				console.log(this.orderPaymentData.shop_goods_list.express_type, 'วิธีการจัดส่ง')
				this.orderCreateData.delivery_type = 'express'
				this.orderCreateData.delivery_type_name = 'การจัดส่งโลจิสติกส์'
			}

			let h = new Date().getHours().toString()
			let m = new Date().getMinutes().toString()
			if (h.length == 1) {
				h = "0" + h
			}
			if (m.length == 1) {
				m = "0" + m
			}
			let nowTime = h + ":" + m

			if (data.shop_goods_list.local_config) {
				if (data.shop_goods_list.local_config.info && data.shop_goods_list.local_config.info.time_is_open == 1) {
					this.orderCreateData.delivery.showTimeBar = true
					this.orderCreateData.delivery.buyer_ask_delivery_time = nowTime
				} else {
					this.orderCreateData.delivery.showTimeBar = false
				}
			}

			// if (data.shop_goods_list.express_type != undefined && data.shop_goods_list.express_type[0] != undefined) {
			// 	var express_type = data.shop_goods_list.express_type
			// 	this.orderCreateData.delivery.delivery_type = express_type[0].name
			// 	this.orderCreateData.delivery.delivery_type_name = express_type[0].title
			// 	this.orderCreateData.delivery.store_id = 0
			// 	// หากวิธีการจัดส่งเริ่มต้นคือการจัดส่งถึงร้านค้า
			// 	if (express_type[0].name == "store") {
			// 		if (express_type[0].store_list[0] != undefined) {
			// 			this.orderCreateData.delivery.store_id = express_type[0].store_list[0].store_id
			// 		}
			// 	}
			// }


			if (data.shop_goods_list.coupon_list != undefined && data.shop_goods_list.coupon_list[0] != undefined) {
				var coupon_list = data.shop_goods_list.coupon_list
				this.orderCreateData.coupon.coupon_id = coupon_list[0].coupon_id
				this.orderCreateData.coupon.coupon_money = coupon_list[0].money
			}

			if (this.orderPaymentData.is_virtual) {
				this.orderCreateData.member_address = {
					mobile: ""
				}
			}

			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.orderPaymentData.shop_goods_list.goods_list.forEach((v) => {
				if (v.sku_spec_format) {
					console.log(v)
					v.sku_spec_format = JSON.parse(v.sku_spec_format);
				} else {
					v.sku_spec_format = [];
				}
			});
			this.orderCalculate()
		},

		/**
		 * การคํานวณคําสั่งซื้อ
		 */
		orderCalculate() {
			this.fullscreenLoading = true

			let siteId = this.orderPaymentData.shop_goods_list.site_id

			var deliveryObj = {}
			deliveryObj[siteId] = this.orderCreateData.delivery

			var messageObj = {}
			messageObj[siteId] = this.orderCreateData.buyer_message

			var data = this.$util.deepClone(this.orderCreateData)
			data.delivery = JSON.stringify(deliveryObj)
			data.buyer_message = JSON.stringify(messageObj)
			data.member_address = JSON.stringify(data.member_address)

			calculate(data)
				.then(res => {
					const {
						code,
						message,
						data
					} = res
					if (code >= 0) {
						this.orderPaymentData.delivery_money = res.data.delivery_money
						this.orderPaymentData.coupon_money = res.data.coupon_money
						this.orderPaymentData.invoice_money = res.data.invoice_money
						this.orderPaymentData.promotion_money = res.data.promotion_money
						this.orderPaymentData.order_money = res.data.order_money
						this.orderPaymentData.balance_money = res.data.balance_money
						this.orderPaymentData.pay_money = res.data.pay_money
						this.orderPaymentData.goods_money = res.data.goods_money
					} else {
						this.$notify({
							message: message, //ข้อมูลที่ได้รับแจ้ง
							title: "คำเตือน",type: "warning",
							offset: 225,
							duration: 3000,
							onClose: () => {
								this.$router.go(-1)
								return false
							}
						})
						return
					}
					this.fullscreenLoading = false
				})
				.catch(err => {
					const {
						code,
						message,
						data
					} = err
					this.$notify.error({title: 'Error',message: message});
					this.fullscreenLoading = false
				})
		},

		/**
		 * เลือกวิธีการจัดส่ง
		 */
		selectDeliveryType(data) {
			this.orderCreateData.delivery.delivery_type = data.name
			this.orderCreateData.delivery.delivery_type_name = data.title

			this.orderCreateData.delivery_type = data.name
			this.orderCreateData.delivery_type_name = data.title
			// หากเป็นการจัดส่งที่ร้าน
			if (data.name == "store") {
				data.store_list.forEach(function(e, i) {
					data.store_list[i]["store_address"] = e.full_address + e.address
				})

				if (data.store_list[0] != undefined) {
					this.orderCreateData.delivery.store_id = data.store_list[0].store_id
				}
				this.dialogStore = true
				this.storeList = data.store_list
			} else if (data.name == "local") {
				this.deliveryTime = true
			}
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * เลือกจุดรับสินค้า
		 * @param {Object} item
		 */
		selectStore(item) {
			console.log(item, 'selectStore')
			if (!item) return;
			let store_id = item.store_id
			this.dialogStore = false
			this.orderCreateData.delivery.store_id = store_id
			this.orderCreateData.delivery.store_name = item.store_name
			Object.assign(this.orderPaymentData, this.orderCreateData)
			this.storeRadio = item
			this.orderCalculate()
			this.$forceUpdate()
		},

		/**
		 * แสดงข้อเสนอร้านค้า
		 * @param {Object} data
		 */
		openSitePromotion(data) {
			this.sitePromotion = data
			if (this.promotionInfo) {
				this.promotionInfo = false
			} else {
				this.promotionInfo = true
			}
		},

		/**
		 * ใช้ยอดเงินคงเหลือหรือไม่
		 */
		useBalance(type) {
			if (this.orderCreateData.is_balance) this.orderCreateData.is_balance = 0
			else this.orderCreateData.is_balance = 1
			this.orderCalculate()
			this.$forceUpdate()
		},

		orderCreate() {
			if (this.verify()) {
				if (this.isSub) return
				this.isSub = true

				var loading = this.$loading({
					lock: true,
					text: "การส่งคําสั่งซื้อ...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				let siteId = this.orderPaymentData.shop_goods_list.site_id

				var deliveryObj = this.orderCreateData.delivery

				var messageObj = this.orderCreateData.buyer_message

				var data = this.$util.deepClone(this.orderCreateData)
				data.delivery = JSON.stringify(deliveryObj)
				data.buyer_message = messageObj
				data.member_address = JSON.stringify(data.member_address)

				orderCreate(data)
					.then(res => {
						const {
							code,
							message,
							data
						} = res
						loading.close()
						if (code >= 0) {
							this.$store.dispatch("order/removeSeckillOrderCreateData", "")
							if (this.orderPaymentData.pay_money == 0) {
								this.$router.push({
									path: "/result",
									query: {
										code: data
									}
								})
							} else {
								this.$router.push({
									path: "/pay",
									query: {
										code: data
									}
								})
							}
						} else {
							this.$notify({
								message: message,
								title: "คำเตือน",type: "warning"
							})
						}
					})
					.catch(err => {
						loading.close()
						this.isSub = false
						const {
							code,
							message,
							data
						} = err
						this.$notify.error({title: 'Error',message: message});
					})
			}
		},
		/**
		 * การยืนยันคําสั่งซื้อ
		 */
		verify() {
			if (this.orderPaymentData.is_virtual == 1) {
				if (!this.orderCreateData.member_address.mobile.length) {
					this.$notify({
						message: "กรุณากรอกหมายเลขโทรศัพท์มือถือของคุณ",
						title: "คำเตือน",type: "warning"
					})
					return false
				}
			
			}

			if (this.orderPaymentData.is_virtual == 0) {
				if (!this.orderPaymentData.member_address) {
					this.$notify({
						message: "โปรดเลือกที่อยู่จัดส่งของคุณก่อน",
						title: "คำเตือน",type: "warning"
					})

					return false
				}




				let deliveryVerify = true

				for (let key in this.orderCreateData.delivery) {
					if (JSON.stringify(this.orderCreateData.delivery[key]) == "{}") {
						deliveryVerify = false

						this.$notify({
							message: 'ร้าน"' + this.orderPaymentData.shop_goods_list[key].site_name + '"ไม่มีการตั้งค่าวิธีการจัดส่ง',
							title: "คำเตือน",type: "warning"
						})

						break
					}
					if (this.orderCreateData.delivery[key].delivery_type == "store" && this.orderCreateData.delivery[key].store_id ==
						0) {
						deliveryVerify = false
						this.$notify({
							message: 'ร้าน"' + this.orderPaymentData.shop_goods_list[key].site_name + '"ไม่มีร้านค้าให้มารับ,โปรดเลือกวิธีการจัดส่งอื่น',
							title: "คำเตือน",type: "warning"
						})

						break
					}
				}
				if (!deliveryVerify) return false
			}
			console.log(this.orderPaymentData.delivery_type)
			console.log(this.orderCreateData.delivery.store_name)
			if (this.orderPaymentData.delivery_type == 'store') {
				if (!this.orderCreateData.delivery.store_name) {
					this.$notify({
						message: 'โปรดเลือกร้านรับสินค้า',
						title: "คำเตือน",type: "warning"
					})
					return false
				}
			}




			// if (this.orderCreateData.is_balance == 1 && this.orderCreateData.pay_password == "") {
			// 	this.dialogpay = true
			// 	return false
			// }
			return true
		},
		/**
		 * ป้อนรหัสผ่านการชําระเงิน
		 */
		input() {
			if (this.password.length == 6) {
				var loading = this.$loading({
					lock: true,
					text: "การจ่ายเงิน",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)"
				})

				checkpaypassword({
						pay_password: this.password
					})
					.then(res => {
						const {
							code,
							message,
							data
						} = res
						loading.close()
						if (code >= 0) {
							this.orderCreateData.pay_password = this.password
							this.orderCreate()
							this.dialogpay = false
						} else {
							this.$notify({
								message: message,
								title: "คำเตือน",type: "warning"
							})
						}
					})
					.catch(err => {
						loading.close()
						const {
							code,
							message,
							data
						} = err
						this.$notify.error({title: 'Error',message: message});
					})
			}
		},

		textarea() {
			this.$forceUpdate()
		},
		bindTimeChange(time) {
			this.time = time
			this.orderCreateData.delivery.buyer_ask_delivery_time = this.time
		},
		setDeliveryTime() {
			this.deliveryTime = false
			this.orderCreateData.delivery.buyer_ask_delivery_time = this.time
		},
		imageError(index) {
			this.orderPaymentData.shop_goods_list.goods_list[index].sku_image = this.defaultGoodsImage
		},
		setPayPassword() {
			this.$router.pushToTab("/member/security");
		}
	}
}
